import React, { useState, useEffect, useRef } from "react";
import "./main.css";
import { toast } from "react-toastify";

const Main = () => {
  const [isRotating, setIsRotating] = useState(false);
  const [currentRotate, setCurrentRotate] = useState(0);
  const [showMsg, setShowMsg] = useState("");
  const [codeUsed, setCodeUsed] = useState(false);
  const [giftCode, setGiftCode] = useState("");
  const [btnWheelDisabled, setBtnWheelDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const wheelRef = useRef(null);

  const validGiftCode = "TooL63gZFT"; // Mã quà tặng hợp lệ
  const timeRotate = 7000; // 7 giây
  const randomNumber = [
    1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 3, 4, 5, 6, 7, 8, 9,
  ]; // Số ngẫu nhiên từ 1.1 đến 2.0
  const getRandomNumber = () => {
    const randomIndex = Math.floor(Math.random() * randomNumber.length); // Lấy một chỉ số ngẫu nhiên
    return randomNumber[randomIndex]; // Trả về giá trị tại chỉ số ngẫu nhiên
  };
  //=====< Danh sách phần thưởng >=====
  const listGift = [
    { text: "I phone 16 Pro Max", percent: 0 / 100 },
    { text: "28K", percent: 0 / 100 },
    { text: "888k", percent: 0 / 100 },
    { text: "Airpods 2", percent: 100 / 100 },
    { text: "588K", percent: 0 / 100 },
    { text: "Chúc bạn may mắn lần sau", percent: 0 / 100 },
    { text: "388k", percent: 0 / 100 },
    { text: "188k", percent: 0 / 100 },
    { text: "58k", percent: 0 / 100 },
    { text: "888k", percent: 0 / 100 },
    { text: "888k", percent: 0 / 100 },
    { text: "888k", percent: 0 / 100 },
  ];

  const size = listGift.length; // số lượng quà tặng
  const rotate = 360 / size; // góc quay mỗi phần thưởng
  const skewY = 90 - rotate; // góc nghiêng của phần thưởng (mỗi phần thưởng có 36 độ)

  useEffect(() => {
    // Tạo các phần thưởng trên vòng quay
    const wheel = wheelRef.current;
    listGift.forEach((item, index) => {
      const elm = document.createElement("li");
      elm.style.transform = `rotate(${rotate * index}deg) skewY(-${skewY}deg)`;

      elm.innerHTML = `<p style="transform: skewY(${skewY}deg) rotate(${
        rotate / 2
      }deg);" class="text text-${(index % 7) + 1}">
            <b>${item.text}</b>
        </p>`;
      wheel.appendChild(elm);
    });
  }, [listGift, rotate, skewY]);

  const rotateWheel = (currentRotate, index) => {
    const wheel = wheelRef.current;
    wheel.style.transform = `rotate(${
      currentRotate + 90 - index * rotate - rotate / getRandomNumber()
    }deg)`; //góc quay tự nhiên random từ 1.1 đến 10
  };

  const getGift = (randomNumber) => {
    let currentPercent = 0;
    let gift = null;

    listGift.forEach((item, index) => {
      currentPercent += item.percent;
      if (!gift && randomNumber <= currentPercent) {
        gift = { ...item, index };
      }
    });
    return gift;
  };

  const showGift = (gift) => {
    setTimeout(() => {
      setIsRotating(false);
      setShowMsg(`Phần thưởng: "${gift.text}"`);
      setBtnWheelDisabled(true);
      setCodeUsed(true); // Đánh dấu mã quà tặng đã được sử dụng
      setGiftCode(""); // Xóa giá trị mã quà tặng sau mỗi lần quay
      setShowModal(true);
    }, timeRotate);
  };

  const start = () => {
    setShowMsg("");
    setIsRotating(true);
    const random = Math.random();
    const gift = getGift(random);
    const fullRotate = 360 * 10; // 10 vòng
    rotateWheel(fullRotate, gift.index);
    showGift(gift);
  };

  const handleConfirmClick = () => {
    if (!codeUsed && giftCode.trim() === validGiftCode) {
      setBtnWheelDisabled(false); // Bật nút quay
      toast.success("Bạn có 1 lượt quay.");
    } else if (codeUsed) {
      toast.error("Mã quà tặng đã được sử dụng.");
    } else {
      toast.error("Mã quà tặng không hợp lệ.");
    }
  };

  const handleWheelClick = () => {
    if (!isRotating) {
      start();
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload(); // Làm mới trang khi đóng modal
  };

  return (
    <div>
      <section className="main">
        <h1 className="title">VÒNG QUAY MAY MẮN - PHÁT LỘC - PHÁT TÀI</h1>
        <div>
          <ul className="wheel" ref={wheelRef}></ul>
          <span className="pointer"></span>
        </div>
        <div clasName="group-wheel">
          <input
            type="text"
            className="gift-code"
            placeholder="Nhập gift code"
            value={giftCode}
            onChange={(e) => setGiftCode(e.target.value)}
          />
          <button className="btn--confirm" onClick={handleConfirmClick}>
            Xác nhận
          </button>
        </div>
        <button
          className="btn--wheel"
          disabled={btnWheelDisabled}
          onClick={handleWheelClick}
        >
          Quay thưởng
        </button>
      </section>
      <h1 className="msg">{showMsg}</h1>
      {/* Modal hiển thị sau khi quay */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Bạn đã quay vào ô: {showMsg}</h2>
            <button onClick={handleModalClose}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
